// @pages/workspace/DocumentSelection.page.tsx
import React, { useEffect, useCallback, useMemo } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { WorkspaceRoutes, AppRoutes, DocumentRoutes, AuthenticationRoutes } from '@navigation/routes';
import { WorkspaceRoutesParamList, AppStackParamList } from '@model/navigation';
import { DocumentID, UserDocument } from '@model/documents/core';
import DocumentSelectionComponent, { DocumentSelectionComponentProps } from '@components/workspace/DocumentSelection.component';
import { useLifecycleClient } from '@contexts/LifecycleClient.context';
import { useDocumentContext } from '@contexts/Document.context';
import { useDocumentListContext } from '@contexts/DocumentList.context';
import { enabledFeatures, FeaturesEnum } from '@src/utils/enabledFeatures';
import { useAuthenticationClient } from '@contexts/AuthenticationClient.context';
import { pipe } from 'fp-ts/lib/function';
import * as T from 'fp-ts/lib/Task'; // Import Task utilities
import * as TE from 'fp-ts/lib/TaskEither'; // Import Task utilities
import { useTaskBasedLoadingSetters } from '@contexts/Loading.context'; // For showing loading state and handling errors
import { handleLifecycleErrorTask } from '@utils/errorHandlers/lifecycleErrorHandler';
import { handleAuthenticationErrorTask } from '@utils/errorHandlers/errorHandlers';
import {AuthenticationError} from "@model/clients/AuthenticationApi";
import {LifecycleError} from "@model/clients/LifecycleApi";
import {Task} from "fp-ts/Task";
import {PaymentClientProvider, usePaymentClient} from "@contexts/PaymentClient.context";
import {PaymentError} from "@model/clients/PaymentApi";
import {handlePaymentErrorTask} from "@utils/errorHandlers/paymentErrorHandler";

type DocumentSelectionProps = {
    navigation: StackNavigationProp<WorkspaceRoutesParamList, typeof WorkspaceRoutes.DOCUMENT_SELECTION>;
    appNavigator: StackNavigationProp<AppStackParamList, typeof AppRoutes.WORKSPACE>;
};

const DocumentSelectionPage: React.FC<DocumentSelectionProps> = ({ appNavigator, navigation }) => {
    const { availableDocuments, fetchDocuments } = useDocumentListContext();
    const { lifecycleClient } = useLifecycleClient();
    const { setDocumentTask } = useDocumentContext();
    const { authenticationClient } = useAuthenticationClient();
    const { triggerLoadingState, triggerSuccessState, triggerErrorState } = useTaskBasedLoadingSetters();
    const { paymentClient} = usePaymentClient();

    useEffect(() => {
        void fetchDocuments(); // Fetch the documents when the component mounts
    }, [fetchDocuments]);

    // Memoize navigateToReviewPageTask to avoid re-creating it on every render
    const navigateToReviewPageTask = useCallback((document: UserDocument): T.Task<void> =>
            pipe(
                setDocumentTask(document),
                T.flatMap(() => triggerSuccessState),
                T.flatMap(() => T.fromIO(() =>
                    appNavigator.navigate(AppRoutes.DOCUMENT, {
                        screen: DocumentRoutes.REVIEW,
                    })
                ))
            ),
        [setDocumentTask, triggerSuccessState, appNavigator]);

    // Memoize handleSelectDocument to avoid re-creating it on every render
    const handleSelectDocument = useCallback((documentID: DocumentID): T.Task<void> =>
            pipe(
                TE.fromTask(triggerLoadingState),
                TE.flatMap(() => lifecycleClient.fetchDocument(documentID)),
                TE.flatMap((userDocument: UserDocument) => TE.rightTask(navigateToReviewPageTask(userDocument))),
                TE.getOrElse((error: LifecycleError) => handleLifecycleErrorTask(error, triggerErrorState))
            ),
        [triggerLoadingState, lifecycleClient, handleLifecycleErrorTask, triggerErrorState, navigateToReviewPageTask]);

    // Memoize handleLogout to avoid re-creating it on every render
    const handleLogout = useMemo((): T.Task<void> =>
        pipe(
            TE.fromTask(triggerLoadingState),
            TE.flatMap(() => authenticationClient.logout()),
            TE.flatMap(() => TE.rightTask(triggerSuccessState)),
            TE.flatMap(() =>
                TE.rightTask(
                    T.fromIO(() =>
                        appNavigator.navigate(AppRoutes.AUTH, {
                            screen: AuthenticationRoutes.LOGIN,
                        })
                    )
                )
            ),
            TE.getOrElse((error: AuthenticationError) => handleAuthenticationErrorTask(error, triggerErrorState))
        ), [triggerLoadingState, authenticationClient, handleAuthenticationErrorTask, triggerErrorState, triggerSuccessState, appNavigator]
    );
    // Memoize handleCreateDocument to avoid re-creating it on every render
    const handleCreateDocument = useCallback(
            T.fromIO(() => navigation.navigate(WorkspaceRoutes.DOCUMENT_CREATION)),
        [navigation]);

    const handleSubscriptionCancel: Task<void> = useCallback(pipe(
        TE.fromTask(triggerLoadingState),
        TE.flatMap(() => paymentClient.cancelSubscription()),
        TE.fold(
            (error: PaymentError) => handlePaymentErrorTask(error, triggerErrorState),
            () => pipe(
                triggerSuccessState,
                T.flatMap(() => handleLogout)
            )
        )
    ), [triggerLoadingState, paymentClient, triggerErrorState, handlePaymentErrorTask, handleLogout, triggerSuccessState])

    // Memoize documentSelectionProps to avoid re-creating it on every render
    const documentSelectionProps: DocumentSelectionComponentProps = useMemo(() => ({
        onCreateDocumentTask: handleCreateDocument,
        onSelectDocumentTask: handleSelectDocument,
        availableDocuments,
        onLogoutTask: handleLogout,
        handleSubscriptionCancelTask: handleSubscriptionCancel
    }), [handleCreateDocument, handleSelectDocument, availableDocuments, handleLogout, handleSubscriptionCancel]);

    return  (
        <DocumentSelectionComponent {...documentSelectionProps} />
    )
};

export default DocumentSelectionPage;
