// @contexts/Loading.context.tsx
import React, { createContext, useContext, ReactNode, useCallback, useMemo } from 'react';
import { RequestState } from '@model/LoadingContext';
import * as T from 'fp-ts/lib/Task';
import { useTaskState } from '@utils/useTaskState';
import { pipe } from 'fp-ts/lib/function';

// Contexts
const LoadingStateContext = createContext<RequestState | undefined>(undefined);
const LoadingErrorContext = createContext<{ errorMessage: string; additionalInfo?: string } | undefined>(undefined);
const LoadingSettersContext = createContext<LoadingSetters | undefined>(undefined);

export type LoadingSetters = {
    triggerLoadingState: T.Task<void>;
    triggerSuccessState: T.Task<void>;
    triggerIdleState: T.Task<void>;
    triggerErrorState: (message: string, info?: string) => T.Task<void>;
};

export const LoadingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [state, setStateTask] = useTaskState<RequestState>('idle');
    const [errorMessage, setErrorMessageTask] = useTaskState<string>('');
    const [additionalInfo, setAdditionalInfoTask] = useTaskState<string | undefined>(undefined);

    // Memoize task-based setters
    const triggerLoadingState = useMemo(() => setStateTask('loading'), [setStateTask]);
    const triggerSuccessState = useMemo(() => setStateTask('success'), [setStateTask]);
    const triggerIdleState = useMemo(() => setStateTask('idle'), [setStateTask]);
    const triggerErrorState = useCallback(
        (message: string, info?: string): T.Task<void> =>
            pipe(
                setErrorMessageTask(message),
                T.chain(() => setAdditionalInfoTask(info)),
                T.chain(() => setStateTask('error'))
            ),
        [setErrorMessageTask, setAdditionalInfoTask, setStateTask]
    );

    // Memoize the setters object
    const settersValue = useMemo(
        () => ({
            triggerLoadingState,
            triggerSuccessState,
            triggerIdleState,
            triggerErrorState,
        }),
        [triggerLoadingState, triggerSuccessState, triggerIdleState, triggerErrorState]
    );

    return (
        <LoadingStateContext.Provider value={state}>
            <LoadingErrorContext.Provider value={{ errorMessage, additionalInfo }}>
                <LoadingSettersContext.Provider value={settersValue}>
                    {children}
                </LoadingSettersContext.Provider>
            </LoadingErrorContext.Provider>
        </LoadingStateContext.Provider>
    );
};

// Hook to access loading state
export const useLoadingState = (): RequestState => {
    const context = useContext(LoadingStateContext);
    if (context === undefined) {
        throw new Error('useLoadingState must be used within a LoadingProvider');
    }
    return context;
};

// Hook to access loading error
export const useLoadingError = (): { errorMessage: string; additionalInfo?: string } => {
    const context = useContext(LoadingErrorContext);
    if (context === undefined) {
        throw new Error('useLoadingError must be used within a LoadingProvider');
    }
    return context;
};

// Hook to access task-based loading setters
export const useTaskBasedLoadingSetters = (): LoadingSetters => {
    const context = useContext(LoadingSettersContext);
    if (context === undefined) {
        throw new Error('useTaskBasedLoadingSetters must be used within a LoadingProvider');
    }
    return context;
};
