// @pages/document/Review.page.tsx
import React from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { DocumentRoutes } from '@navigation/routes';
import { DocumentRoutesParamList } from '@model/navigation';
import SectionList from '@components/document/review/SectionList.component';
import { useContentClient } from '@contexts/ContentClient.context';
import { useNonNullDocumentContext } from '@contexts/Document.context';
import {Section, SectionID} from '@model/documents/core'; // Import the specific Section type
import { pipe } from 'fp-ts/lib/function';
import { fold } from 'fp-ts/lib/TaskEither';
import * as T from 'fp-ts/lib/Task'; // Import Task utilities
import * as TE from 'fp-ts/lib/TaskEither'; // Import Task utilities
import { useTaskBasedLoadingSetters } from '@contexts/Loading.context'; // For loading, success, and error handling
import { handleContentErrorTask } from '@utils/errorHandlers/contentErrorHandler';
import {UserSectionReplacement} from "@model/clients/ContentApi"; // Import task-based ContentError handler

type ReviewProps = {
    navigation: StackNavigationProp<DocumentRoutesParamList, typeof DocumentRoutes.REVIEW>;
};

const ReviewPage: React.FC<ReviewProps> = ({ navigation }) => {
    const { contentClient } = useContentClient();
    const { document, activeSectionID } = useNonNullDocumentContext();
    const { triggerLoadingState, triggerSuccessState, triggerErrorState } = useTaskBasedLoadingSetters(); // Use task-based loading context setters

    // Task to navigate to the prompt page
    const navigateToPromptTask = (probingQuestions: string): T.Task<void> =>
        T.fromIO(() =>
            navigation.navigate(DocumentRoutes.PROMPT, { probingQuestion: probingQuestions })
        );

    // Task to navigate to the refinement page
    const navigateToRefineTask: T.Task<void> =
        T.fromIO(() => navigation.navigate(DocumentRoutes.REFINE));

    const handleDraft : T.Task<void> =
        pipe(
            triggerLoadingState, // Start loading when generating section questions
            T.chain(() =>
                pipe(
                    contentClient.generateSectionQuestions(document.id, { section_id: activeSectionID! }),
                    TE.flatMap((response) => TE.rightTask(navigateToPromptTask(response.probing_questions))),
                    fold(
                        (error) => handleContentErrorTask(error, triggerErrorState), // Handle error case with task-based error handler
                        () => triggerSuccessState
                    )
                )
            )
        );

    const handleEdits: T.Task<void> = T.fromIO(() => navigation.navigate(DocumentRoutes.FINAL_EDITS));

    return (
        <SectionList
            handleDraft={handleDraft}
            handleRefine={navigateToRefineTask}
            handleEdits={handleEdits}
        />
    );
};

export default ReviewPage;
