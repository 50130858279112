import React, { useCallback } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import {AppRoutes, PaymentRoutes, WorkspaceRoutes} from '@navigation/routes';
import {AppStackParamList, PaymentStackParamList} from '@model/navigation';
import { StripeSetupIntentSecret, SubscriptionPlan } from '@model/clients/PaymentApi';
import { usePaymentClient } from '@contexts/PaymentClient.context';
import { pipe } from 'fp-ts/lib/function';
import * as T from 'fp-ts/lib/Task'; // Import Task utilities
import * as TE from 'fp-ts/lib/TaskEither'; // Import TaskEither utilities
import { useTaskBasedLoadingSetters } from '@contexts/Loading.context'; // Import the task-based loading context
import { handlePaymentErrorTask } from '@utils/errorHandlers/paymentErrorHandler'; // Task-based PaymentError handler
import ChooseSubscriptionComponent from '@components/payment/ChooseSubscription.component';
import {RouteProp} from "@react-navigation/native";

type ChoosePackageProps = {
    navigation: StackNavigationProp<PaymentStackParamList, typeof PaymentRoutes.CHOOSE_SUBSCRIPTION>;
    appNavigator: StackNavigationProp<AppStackParamList, typeof AppRoutes.PAYMENT>;
    route: RouteProp<PaymentStackParamList, typeof PaymentRoutes.CHOOSE_SUBSCRIPTION>;
};

const ChooseSubscriptionPage: React.FC<ChoosePackageProps> = ({ navigation, appNavigator, route }) => {
    const { paymentClient } = usePaymentClient();
    const { triggerLoadingState, triggerSuccessState, triggerErrorState } = useTaskBasedLoadingSetters(); // Use task-based loading context setters
    const {subscription_status} = route.params

    // Task for navigating to the payment setup page
    const navigateToPaymentSetupTask = useCallback(
        (paymentIntentSecret: StripeSetupIntentSecret): T.Task<void> =>
            T.fromIO(() => navigation.navigate(PaymentRoutes.PAYMENT_SETUP, { setup_intent_secret: paymentIntentSecret })),
        [navigation]
    );


    // Task for handling success state and navigating to payment setup page
    const handlePaymentSuccessTask = useCallback(
        (paymentIntentSecret: StripeSetupIntentSecret): T.Task<void> =>
            pipe(
                triggerSuccessState, // Set success state
                T.chain(() => navigateToPaymentSetupTask(paymentIntentSecret)) // Navigate to payment setup page
            ),
        [triggerSuccessState, navigateToPaymentSetupTask]
    );

    // Memoize the task to handle subscription package selection
    const moveToConfigurePayment = useCallback(
        (subscription: SubscriptionPlan): T.Task<void> =>
            pipe(
                triggerLoadingState, // Start loading when selecting a package
                T.chain(() =>
                    pipe(
                        paymentClient.setupIntent(subscription),
                        TE.fold(
                            (error) => handlePaymentErrorTask(error, triggerErrorState), // Task-based error handling
                            (paymentIntentSecret) => handlePaymentSuccessTask(paymentIntentSecret as StripeSetupIntentSecret) // Handle payment success
                        )
                    )
                )
            ),
        [triggerLoadingState, paymentClient, triggerErrorState, handlePaymentSuccessTask]
    );

    return <ChooseSubscriptionComponent onPackageSelectionTask={moveToConfigurePayment} subscriptionStatus = {subscription_status} />;
};

export default ChooseSubscriptionPage;
