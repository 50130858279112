// @components/writing/review/Section.component.tsx
import React from 'react';
import { Section as SectionType, SectionID } from '@model/documents/core';
import SectionCard from './SectionCard.component';
import SectionActions from './SectionActions.component';
import * as T from 'fp-ts/lib/Task';
import {UserSectionReplacement} from "@model/clients/ContentApi"; // Import Task utilities

type SectionProps = {
    section: SectionType;
    active: boolean;
    handleRefine: T.Task<void>; // Task-based handler for refining
    handleFinalEdits: T.Task<void>; // Task-based handler for final edits
    handleDraft:  T.Task<void>; // Task-based handler for drafting
    setActiveSection: (sectionID: SectionID) => T.Task<void>; // Task-based setter for active section
};

const Section: React.FC<SectionProps> = ({
                                             section,
                                             active,
                                             handleRefine,
                                             handleFinalEdits,
                                             handleDraft,
                                             setActiveSection,
                                         }) => {

    return (
        <>
            <SectionCard section={section} active={active} onPressTask={setActiveSection(section.metadata.id)} />
            {active && (
                <SectionActions
                    sectionContents={section.section_contents}
                    handleDraft={handleDraft}
                    handleRefine={handleRefine}
                    handleFinalEdits={handleFinalEdits}
                />
            )}
        </>
    );
};

export default React.memo(Section, (prevProps, nextProps) => {
    return prevProps.active === nextProps.active && prevProps.section === nextProps.section;
});
