// @components/Refine.component.tsx
import React, { useState } from 'react'; // Using useState instead of useTaskState
import { View, Text, TextInput, Button, ActivityIndicator, ScrollView } from 'react-native';
import { FeedbackStyles as styles } from '@styles/Feedback.style';
import { Section } from '@model/documents/core';
import * as T from 'fp-ts/lib/Task';

type RefineComponentProps = {
    onSubmitTask: (feedback: string) => T.Task<void>;
    mostRecentSection: Section | null;
};

const RefineComponent: React.FC<RefineComponentProps> = ({
                                                             onSubmitTask,
                                                             mostRecentSection,
                                                         }) => {
    const [feedback, setFeedbackImpure] = useState(''); // useState with renamed setter
    if (mostRecentSection) {
        return (
            <View style={styles.container}>
                <Text style={styles.header}>AI Refine</Text>
                <Text style={styles.subheader}>{mostRecentSection.metadata.name}:</Text>
                <ScrollView style={styles.sectionContainer}>
                    <Text style={styles.section}>{mostRecentSection.section_contents}</Text>
                </ScrollView>
                <TextInput
                    style={styles.input}
                    value={feedback}
                    onChangeText={setFeedbackImpure} // Direct use of the useState setter
                    placeholder="Enter feedback here"
                    multiline
                />
                <Button title="Submit Feedback" onPress={() => void onSubmitTask(feedback)()} />
            </View>
        );
    } else {
        return (
            <View style={styles.loadingContainer}>
                <ActivityIndicator size="large" color="#0000ff" />
                <Text>Loading ...</Text>
            </View>
        );
    }
};

export default RefineComponent;
