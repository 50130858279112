// @services/ouroboros/DefaultContentClient.ts

import { ContentClient } from '@services/clients/ContentClient';
import { DocumentID } from '@model/documents/core';
import {
    ReplaceSectionRequest,
    ReplaceSectionResponse,
    AnswerSectionQuestionsRequest,
    AnswerSectionQuestionsResponse,
    GenerateSectionQuestionsRequest,
    GenerateSectionQuestionsResponse,
    RefineRequest,
    RefineResponse,
    AnswerDocumentQuestionsRequest,
    GenerateDocumentQuestionsResponse,
    ContentError,
} from '@model/clients/ContentApi';
import API_URL from '@utils/config';
import * as t from 'io-ts';
import { v7 as uuidv7 } from 'uuid';
import { TaskEither, tryCatch, left, chain, map } from 'fp-ts/lib/TaskEither';
import { pipe } from 'fp-ts/lib/function';

export class DefaultContentClient implements ContentClient {
    constructor() {
        // No dependencies
    }

    private generateTraceId(): string {
        return uuidv7();
    }

    private fetchWithTrace(url: string, options: RequestInit): TaskEither<ContentError, Response> {
        const traceId = this.generateTraceId();
        options.headers = {
            ...options.headers,
            'X-Trace-ID': traceId,
        };
        options.credentials = 'include';

        return tryCatch(
            async () => await fetch(url, options),
            (error) =>
                ({
                    type: 'NetworkError',
                    message: 'Failed to connect to the server. Please check your internet connection.',
                } as ContentError)
        );
    }

    private validateResponse<T>(
        response: Response,
        validator: t.Type<T>
    ): TaskEither<ContentError, T> {
        return tryCatch(
            async () => {
                const data = await response.json();
                const decoded = validator.decode(data);
                if (decoded._tag === 'Right') {
                    return decoded.right;
                } else {
                    throw new Error('Validation failed');
                }
            },
            () =>
                ({
                    type: 'ValidationError',
                    message: 'Response validation failed',
                } as ContentError)
        );
    }

    refineSection(
        documentId: DocumentID,
        request: RefineRequest
    ): TaskEither<ContentError, RefineResponse> {
        return pipe(
            this.fetchWithTrace(`${API_URL}/v1/apps/writer/document/refine`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Document-ID': documentId,
                },
                body: JSON.stringify(request),
            }),
            chain((response) =>
                response.ok
                    ? this.validateResponse<RefineResponse>(response, RefineResponse)
                    : left({
                        type: 'ServerError',
                        message: `Server responded with an error: ${response.status}`,
                        statusCode: response.status,
                    } as ContentError)
            )
        );
    }

    replaceSection(
        documentId: DocumentID,
        request: ReplaceSectionRequest
    ): TaskEither<ContentError, ReplaceSectionResponse> {
        return pipe(
            this.fetchWithTrace(`${API_URL}/v1/apps/writer/document/replace-section`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Document-ID': documentId,
                },
                body: JSON.stringify(request),
            }),
            chain((response) =>
                response.ok
                    ? this.validateResponse<ReplaceSectionResponse>(response, ReplaceSectionResponse)
                    : left({
                        type: 'ServerError',
                        message: `Server responded with an error: ${response.status}`,
                        statusCode: response.status,
                    } as ContentError)
            )
        );
    }

    answerSectionQuestions(
        documentId: DocumentID,
        request: AnswerSectionQuestionsRequest
    ): TaskEither<ContentError, AnswerSectionQuestionsResponse> {
        return pipe(
            this.fetchWithTrace(`${API_URL}/v1/apps/writer/document/answer-section-questions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Document-ID': documentId,
                },
                body: JSON.stringify(request),
            }),
            chain((response) =>
                response.ok
                    ? this.validateResponse<AnswerSectionQuestionsResponse>(
                        response,
                        AnswerSectionQuestionsResponse
                    )
                    : left({
                        type: 'ServerError',
                        message: `Server responded with an error: ${response.status}`,
                        statusCode: response.status,
                    } as ContentError)
            )
        );
    }

    generateSectionQuestions(
        documentId: DocumentID,
        request: GenerateSectionQuestionsRequest
    ): TaskEither<ContentError, GenerateSectionQuestionsResponse> {
        return pipe(
            this.fetchWithTrace(`${API_URL}/v1/apps/writer/document/generate-section-questions`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Document-ID': documentId,
                },
                body: JSON.stringify(request),
            }),
            chain((response) =>
                response.ok
                    ? this.validateResponse<GenerateSectionQuestionsResponse>(
                        response,
                        GenerateSectionQuestionsResponse
                    )
                    : left({
                        type: 'ServerError',
                        message: `Server responded with an error: ${response.status}`,
                        statusCode: response.status,
                    } as ContentError)
            )
        );
    }

    answerDocumentQuestions(
        documentId: DocumentID,
        request: AnswerDocumentQuestionsRequest
    ): TaskEither<ContentError, void> {
        return pipe(
            this.fetchWithTrace(`${API_URL}/v1/apps/writer/document/answer-document-questions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Document-ID': documentId,
                },
                body: JSON.stringify(request),
            }),
            map(() => undefined)
        );
    }

    generateDocumentQuestions(
        documentId: DocumentID
    ): TaskEither<ContentError, GenerateDocumentQuestionsResponse> {
        return pipe(
            this.fetchWithTrace(`${API_URL}/v1/apps/writer/document/generate-document-questions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Document-ID': documentId,
                },
            }),
            chain((response) =>
                response.ok
                    ? this.validateResponse<GenerateDocumentQuestionsResponse>(
                        response,
                        GenerateDocumentQuestionsResponse
                    )
                    : left({
                        type: 'ServerError',
                        message: `Server responded with an error: ${response.status}`,
                        statusCode: response.status,
                    } as ContentError)
            )
        );
    }
}
