// @model/clients/PaymentApi.ts

import * as t from 'io-ts';
import { createBrandedDecoder } from '@utils/brandedDecoder';
import { Either } from 'fp-ts/lib/Either';

// Base non-empty string type with io-ts validation
const nonEmptyString = new t.Type<string, string, unknown>(
    'NonEmptyString',
    t.string.is,
    (input, context) =>
        typeof input === 'string' && input.trim().length > 0
            ? t.success(input.trim())
            : t.failure(input, context),
    t.identity
);

// Branding for types
export type SubscriptionPlan = 'Monthly' | 'Annual';


// Stripe Publishable Key
export interface StripePublishableKeyBrand {
    readonly StripePublishableKey: unique symbol;
}

export const StripePublishableKey = createBrandedDecoder(
    t.string, // Validate as a regular string
    (s): s is t.Branded<string, StripePublishableKeyBrand> => s.length > 0, // Brand after validation
    'StripePublishableKey'
);
export type StripePublishableKey = t.TypeOf<typeof StripePublishableKey>;

// Stripe Payment Intent Secret
export interface StripePaymentIntentSecretBrand {
    readonly StripePaymentIntentSecret: unique symbol;
}

export const StripeSetupIntentSecret = createBrandedDecoder(
    t.string, // Validate as a regular string
    (s): s is t.Branded<string, StripePaymentIntentSecretBrand> => s.length > 0, // Brand after validation
    'StripeSetupIntentSecret'
);
export type StripeSetupIntentSecret = t.TypeOf<typeof StripeSetupIntentSecret>;

// Response types with io-ts validation
export const GetPublishableKeyResponse = t.type({
    stripe_publishable_key: StripePublishableKey,
});
export type GetPublishableKeyResponse = t.TypeOf<typeof GetPublishableKeyResponse>;

export const SetupIntentResponse = t.type({
    setup_intent_secret: StripeSetupIntentSecret, // Expect plain string and validate/brand it
});
export type SetupIntentResponse = t.TypeOf<typeof SetupIntentResponse>;

// Define PaymentError type
export type PaymentError =
    | { type: 'NetworkError'; message: string }
    | { type: 'ServerError'; message: string; statusCode: number }
    | { type: 'ValidationError'; message: string }
    | { type: 'UnknownError'; message: string };
