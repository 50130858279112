// @navigation/navigators/Document.navigator.tsx
import React from 'react';
import {createStackNavigator, StackNavigationProp} from '@react-navigation/stack';
import {AppStackParamList, DocumentRoutesParamList} from '@model/navigation';
import ReviewPage from '@pages/document/Review.page';
import FinalEditsPage from '@pages/document/FinalEdits.page';
import PromptPage from '@pages/document/Prompt.page';
import RefinePage from '@pages/document/Refine.page';
import {WorkspaceRoutes, DocumentRoutes, AppRoutes} from '@navigation/routes';
import { View } from 'react-native';
import DocumentDetailsPromptPage from "@pages/document/DocumentDetailsPrompt.page";
import {ContentClientProvider} from "@contexts/ContentClient.context";

const Stack = createStackNavigator<DocumentRoutesParamList>();

type DocumentNavigatorProps = {
    navigation: StackNavigationProp<AppStackParamList, typeof AppRoutes.DOCUMENT>;
};

const DocumentNavigator: React.FC<DocumentNavigatorProps>  = () => {
    return (
            <ContentClientProvider>
                <Stack.Navigator
                    initialRouteName={DocumentRoutes.REVIEW}
                    screenOptions={{
                        cardStyle: { backgroundColor: '#053A3A' },
                        cardOverlay: () => (
                            <View
                                style={{
                                    flex: 1,
                                    backgroundColor: '#053A3A',
                                }}
                            />
                        ),
                        detachPreviousScreen: false
                    }}
                >
                    <Stack.Screen
                        name={DocumentRoutes.REVIEW}
                        component={ReviewPage}
                        options={{ headerShown: false }}
                    />
                    <Stack.Screen
                        name={DocumentRoutes.FINAL_EDITS}
                        component={FinalEditsPage}
                        options={{ headerShown: false }}
                    />
                    <Stack.Screen
                        name={DocumentRoutes.PROMPT}
                        component={PromptPage}
                        options={{ headerShown: false }}
                    />
                    <Stack.Screen
                        name={DocumentRoutes.DOCUMENT_LEVEL_PROMPT}
                        component={DocumentDetailsPromptPage}
                        options={{ headerShown: true }}
                    />
                    <Stack.Screen
                        name={DocumentRoutes.REFINE}
                        component={RefinePage}
                        options={{ headerShown: false }}
                    />
                </Stack.Navigator>
            </ContentClientProvider>
    );
};

export default DocumentNavigator;
