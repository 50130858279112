// @utils/paymentErrorHandler.ts

import { PaymentError } from '@model/clients/PaymentApi';
import * as T from 'fp-ts/lib/Task'; // Import Task utilities

export const handlePaymentErrorTask = (
    error: PaymentError,
    setError: (message: string) => T.Task<void>
): T.Task<void> => {
    switch (error.type) {
        case 'NetworkError':
            return setError('Network error. Please check your internet connection.');
        case 'ValidationError':
            return setError('The response from the server could not be read, please update your app or refresh your web browser. If the problem persists, contact support.');
        case 'ServerError':
            return setError(`Server error: ${error.message} (Status code: ${error.statusCode})`);
        case 'UnknownError':
        default:
            console.error(error);
            return setError('An unknown error occurred. Please try again.');
    }
};
