// @components/workspaces/LoginPage.component.tsx

import React, { useEffect, useCallback, useState } from 'react'; // Use useState instead of useTaskState
import { View, StyleSheet, Image, Dimensions } from 'react-native';
import { TextInput, Button, Card, Title, Paragraph } from 'react-native-paper';
import { GoogleSignin, GoogleSigninButton, statusCodes } from '@react-native-google-signin/google-signin';
import { LinearGradient } from 'expo-linear-gradient';
import { gradientColors } from '@styles/Fancy.style';
import { useAssets } from 'expo-asset';
import { enabledFeatures, FeaturesEnum } from '@src/utils/enabledFeatures';
import { AuthenticationRoutes } from '@src/navigation/routes';
import { NavigationContainerRefWithCurrent, useFocusEffect, useNavigation } from '@react-navigation/native';
import { AuthenticationStackParamList } from '@model/navigation';
import { sexyStyles } from '@src/styles/OuroborosSexyTheme.style';
import { Email, Password } from '@model/clients/AuthenticationApi';
import * as T from 'fp-ts/lib/Task'; // Import Task utilities
import * as TE from 'fp-ts/lib/TaskEither'; // Import TaskEither utilities
import { pipe } from 'fp-ts/lib/function';

const { width, height } = Dimensions.get('window');

export type LoginComponentProps = {
    onLogin: (email: Email, password: Password) => T.Task<void>; // Task-based login
    onCreateAccount: T.Task<void>; // Task-based create account
};

const LoginPage: React.FC<LoginComponentProps> = ({ onLogin }) => {
    const [email, setEmailImpure] = useState<string>(''); // useState with renamed setter
    const [password, setPasswordImpure] = useState<string>(''); // useState with renamed setter
    const [assets, error] = useAssets([require('@public/ouroboros_logo_flat.png')]);

    const navigation: NavigationContainerRefWithCurrent<AuthenticationStackParamList> = useNavigation();

    useEffect(() => {
        if (enabledFeatures[FeaturesEnum.SignInWithGoogle]) {
            void configureGoogleSignInTask(); // Execute the task
        }
    }, []);

    useFocusEffect(
        useCallback(() => {
            const handleKeyPress = (e: KeyboardEvent) => {
                if (e.key === 'Enter' && email && password) {
                    void handleLoginTask(); // Trigger login task when Enter is pressed and fields are populated
                }
            };

            window.addEventListener('keydown', handleKeyPress);

            return () => {
                window.removeEventListener('keydown', handleKeyPress);
            };
        }, [email, password])
    );

    // Task to configure Google Sign-In
    const configureGoogleSignInTask: T.Task<void> = T.fromIO(() => {
        GoogleSignin.configure({
            webClientId: 'YOUR_WEB_CLIENT_ID', // From Google Cloud Console
            offlineAccess: false,
        });
    });

    // Task to handle user login
    const handleLoginTask: T.Task<void> = onLogin(Email(email), Password(password)); // Trigger login task

    // TaskEither to handle Google Sign-In with error handling
    const signInWithGoogleTask = (): TE.TaskEither<Error, void> =>
        TE.tryCatch(
            async () => {
                await GoogleSignin.hasPlayServices();
                const userInfo = await GoogleSignin.signIn();
                console.log('User Info:', userInfo);
            },
            (error: any) => {
                if (error.code === statusCodes.SIGN_IN_CANCELLED) {
                    console.log('User cancelled the login flow');
                } else if (error.code === statusCodes.IN_PROGRESS) {
                    console.log('Operation (e.g., sign in) is in progress already');
                } else if (error.code === statusCodes.PLAY_SERVICES_NOT_AVAILABLE) {
                    console.log('Play services not available or outdated');
                } else {
                    console.error('Some other error happened:', error);
                }
                return new Error('Google Sign-In failed');
            }
        );

    const logo = assets ? <Image style={styles.logoStyle} source={{ uri: assets[0].uri }} /> : null;

    return (
        <LinearGradient colors={gradientColors} style={sexyStyles.background}>
            <View style={sexyStyles.container}>
                <Card style={sexyStyles.card}>
                    <Card.Content>
                        {logo}
                        <Title style={sexyStyles.title}>Ouroboros Docs Login</Title>
                        <TextInput
                            label="Email"
                            value={email}
                            onChangeText={setEmailImpure} // Direct use of the useState setter
                            style={sexyStyles.input}
                            keyboardType="email-address"
                            autoCapitalize="none"
                        />
                        <TextInput
                            label="Password"
                            value={password}
                            onChangeText={setPasswordImpure} // Direct use of the useState setter
                            style={sexyStyles.input}
                            secureTextEntry
                        />
                        <Button mode="contained" onPress={() => handleLoginTask()} style={sexyStyles.button}>
                            Login
                        </Button>
                        {enabledFeatures[FeaturesEnum.ForgotPassword] && (
                            <Button
                                mode="text"
                                onPress={() => navigation.navigate(AuthenticationRoutes.FORGOT_PASSWORD)}
                                style={sexyStyles.textButton}
                            >
                                Forgot Password?
                            </Button>
                        )}
                        {enabledFeatures[FeaturesEnum.SignInWithGoogle] && (
                            <GoogleSigninButton
                                style={styles.googleButton}
                                size={GoogleSigninButton.Size.Wide}
                                color={GoogleSigninButton.Color.Dark}
                                onPress={() => signInWithGoogleTask()()} // Execute TaskEither
                            />
                        )}
                        <Paragraph style={styles.signUpText}>Don't have an account?</Paragraph>
                        <Button
                            mode="text"
                            onPress={() => navigation.navigate(AuthenticationRoutes.CREATE_ACCOUNT)}
                            style={sexyStyles.textButton}
                        >
                            Sign Up
                        </Button>
                    </Card.Content>
                </Card>
            </View>
        </LinearGradient>
    );
};

const styles = StyleSheet.create({
    logoStyle: {
        flex: 1,
        alignSelf: 'center',
        height: 250,
        width: 250,
    },
    googleButton: {
        marginTop: 16,
    },
    signUpText: {
        marginTop: 16,
        textAlign: 'center',
    },
});

export default LoginPage;
