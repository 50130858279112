// @components/ChooseSubscription.component.tsx

import React from 'react';
import { View } from 'react-native';
import { Button, RadioButton, Text, Title, Paragraph, Card } from 'react-native-paper';
import { SubscriptionPlan } from '@model/clients/PaymentApi';
import * as T from 'fp-ts/lib/Task';
import { sexyStyles } from '@styles/OuroborosSexyTheme.style';
import { SubscriptionStatus } from '@model/clients/AuthenticationApi';
import { useTaskState } from '@utils/useTaskState';

type ChoosePackageComponentProps = {
    onPackageSelectionTask: (packageType: SubscriptionPlan) => T.Task<void>;
    subscriptionStatus: SubscriptionStatus;
};

const ChooseSubscriptionComponent: React.FC<ChoosePackageComponentProps> = ({
                                                                                onPackageSelectionTask,
                                                                                subscriptionStatus,
                                                                            }) => {
    const [selectedPackage, setSelectedPackageTask] = useTaskState<SubscriptionPlan>('Monthly'); // Track the selected package

    // Helper text based on subscription status
    const helperText = (() => {
        switch (subscriptionStatus) {
            case 'PaymentFailed':
                return 'There was an issue processing your previous payment, please update your information.';
            case 'PaymentNotYetConfigured':
                return "All packages come with a 30-day free trial (with a verified payment method) and there's no charge for canceling before the trial expires.";
            case 'FreeTrialMode':
                return 'You are currently in free trial mode. Upgrade to a paid plan before the trial expires.';
            case 'Paid':
                return 'Thank you for being a valued subscriber! You can update your plan here.';
            case 'Canceled':
                return 'Your previous subscription was canceled, but you can restart it by purchasing a plan below.';
        }
    })();

    return (
        <View style={sexyStyles.container}>
            <Card style={sexyStyles.card}>
                <Title>Select a Subscription Package</Title>
                <Paragraph>{helperText}</Paragraph>

                {/* Package options */}
                <RadioButton.Group
                    onValueChange={(value: string) => setSelectedPackageTask(value as SubscriptionPlan)()}
                    value={selectedPackage ?? ''}
                >
                    <View style={{ marginVertical: 8 }}>
                        <RadioButton.Item
                            label="Monthly"
                            value="Monthly"
                            status={selectedPackage === 'Monthly' ? 'checked' : 'unchecked'}
                        />
                        <Paragraph>Subscribe monthly for more flexibility. $50/month</Paragraph>
                    </View>

                    <View style={{ marginVertical: 8 }}>
                        <RadioButton.Item
                            label="Annual"
                            value="Annual"
                            status={selectedPackage === 'Annual' ? 'checked' : 'unchecked'}
                        />
                        <Paragraph>Pay annually and get 2 months free! $500/year</Paragraph>
                    </View>
                </RadioButton.Group>

                {/* Confirm button */}
                <Button
                    mode="contained"
                    onPress={() => onPackageSelectionTask(selectedPackage)()}
                    disabled={!selectedPackage} // Disable the button until a package is selected
                    style={{ marginTop: 16 }}
                >
                    Continue to Payment
                </Button>

                {/* Display selected package */}
                {selectedPackage && (
                    <Text style={{ marginTop: 16 }}>
                        You have selected the {selectedPackage} package.
                    </Text>
                )}
            </Card>
        </View>
    );
};

export default ChooseSubscriptionComponent;
