// @pages/document/FinalEdits.page.tsx
import React, {useState} from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { useFocusEffect } from '@react-navigation/native';
import { DocumentRoutes } from '@navigation/routes';
import { DocumentRoutesParamList } from '@model/navigation';
import FinalEditsComponent from '@components/document/FinalEdits.component';
import { useContentClient } from '@contexts/ContentClient.context';
import { useNonNullDocumentContext } from '@contexts/Document.context';
import { pipe } from 'fp-ts/lib/function';
import { fold } from 'fp-ts/lib/TaskEither';
import * as T from 'fp-ts/lib/Task';
import { useTaskBasedLoadingSetters } from '@contexts/Loading.context';
import { handleContentErrorTask } from '@utils/errorHandlers/contentErrorHandler';
import { Section } from '@model/documents/core';
import { UserSectionReplacement } from '@model/clients/ContentApi';
import { useTaskState } from '@utils/useTaskState';

type FinalEditsProps = {
    navigation: StackNavigationProp<DocumentRoutesParamList, typeof DocumentRoutes.FINAL_EDITS>;
};

const FinalEditsPage: React.FC<FinalEditsProps> = ({ navigation }) => {
    const { contentClient } = useContentClient();
    const { document, activeSectionID, updateSectionTask } = useNonNullDocumentContext();
    const [finalEdits, setFinalEditsImpure] = useState('');

    const { triggerLoadingState, triggerSuccessState, triggerErrorState } = useTaskBasedLoadingSetters();

    // Set final edits based on active section
    useFocusEffect(
        React.useCallback(() => {
            if (activeSectionID) {
                const section = document.document_contents.find(
                    (sec) => sec.metadata.id === activeSectionID
                );
                if (section) {
                    setFinalEditsImpure(section.section_contents || ''); // Execute the task
                }
            }
        }, [document, activeSectionID])
    );

    // Navigation task to move to review page
    const navigateToReviewTask: T.Task<void> = T.fromIO(() =>
        navigation.navigate(DocumentRoutes.REVIEW)
    );

    // Task to handle successful update
    const handleUpdateSectionSuccessTask = (updatedSection: Section): T.Task<void> =>
        pipe(
            updateSectionTask(updatedSection),
            T.flatMap(() => navigateToReviewTask),
            T.flatMap(() => triggerSuccessState)
        );

    // Handle final edits submission
    const handleFinalEdits: T.Task<void> = pipe(
        triggerLoadingState,
        T.chain(() =>
            pipe(
                contentClient.replaceSection(document.id, {
                    edits: finalEdits as UserSectionReplacement,
                    section_id: activeSectionID!,
                }),
                fold(
                    (error) => handleContentErrorTask(error, triggerErrorState),
                    (response) => handleUpdateSectionSuccessTask(response.updated_section)
                )
            )
        )
    );

    return (
        <FinalEditsComponent
            finalEdits={finalEdits}
            setFinalEdits={setFinalEditsImpure} // Adjusted setter
            onSubmitTask={handleFinalEdits}
        />
    );
};

export default FinalEditsPage;
