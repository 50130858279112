// @pages/document/Refine.page.tsx
import React, { useEffect } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { DocumentRoutes } from '@navigation/routes';
import { DocumentRoutesParamList } from '@model/navigation';
import RefineComponent from '@components/document/Refine.component';
import { useContentClient } from '@contexts/ContentClient.context';
import { useNonNullDocumentContext } from '@contexts/Document.context';
import { Section } from '@model/documents/core';
import { pipe } from 'fp-ts/lib/function';
import { fold } from 'fp-ts/lib/TaskEither';
import * as T from 'fp-ts/lib/Task';
import { useTaskBasedLoadingSetters } from '@contexts/Loading.context';
import { handleContentErrorTask } from '@utils/errorHandlers/contentErrorHandler';
import { useTaskState } from '@utils/useTaskState';

type RefineProps = {
    navigation: StackNavigationProp<DocumentRoutesParamList, typeof DocumentRoutes.REFINE>;
};

const RefinePage: React.FC<RefineProps> = ({ navigation }) => {
    const { contentClient } = useContentClient();
    const { activeSectionID, updateSectionTask, document } = useNonNullDocumentContext();
    const [mostRecentSection, setMostRecentSectionTask] = useTaskState<Section | null>(null);

    const { triggerLoadingState, triggerSuccessState, triggerErrorState } = useTaskBasedLoadingSetters();

    useEffect(() => {
        if (activeSectionID && document) {
            const section = document.document_contents.find(
                (sec) => sec.metadata.id === activeSectionID
            ) || null;
            setMostRecentSectionTask(section)(); // Execute the task
        }
    }, [activeSectionID, document]);

    // Task to navigate to the review page
    const navigateToReviewTask: T.Task<void> = T.fromIO(() =>
        navigation.navigate(DocumentRoutes.REVIEW)
    );

    // Task to handle success case (updating section and navigating)
    const handleSuccessTask = (updatedSection: Section): T.Task<void> =>
        pipe(
            updateSectionTask(updatedSection),
            T.chain(() => navigateToReviewTask),
            T.chain(() => triggerSuccessState)
        );

    // Now returning the task directly
    const handleRefinement: (feedback: string) => T.Task<void> = feedback => mostRecentSection
        ? pipe(
            triggerLoadingState,
            T.chain(() =>
                pipe(
                    contentClient.refineSection(document.id, {
                        feedback,
                        section_id: mostRecentSection.metadata.id,
                    }),
                    fold(
                        (error) => handleContentErrorTask(error, triggerErrorState),
                        (response) => handleSuccessTask(response.updated_section)
                    )
                )
            )
        )
        : T.of(undefined);

    return (
        <RefineComponent
            onSubmitTask={handleRefinement}
            mostRecentSection={mostRecentSection}
        />
    );
};

export default RefinePage;
