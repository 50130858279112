// @pages/workspace/DocumentCreation.page.tsx
import React from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { AppRoutes, DocumentRoutes, WorkspaceRoutes } from '@navigation/routes';
import { WorkspaceRoutesParamList, AppStackParamList } from '@model/navigation';
import DocumentCreationComponent from '@components/workspace/DocumentCreation.component';
import {CreateDocumentRequest, CreateDocumentResponse, LifecycleError} from '@model/clients/LifecycleApi';
import { useLifecycleClient } from '@contexts/LifecycleClient.context';
import { useDocumentListContext } from '@contexts/DocumentList.context';
import { useDocumentContext } from '@contexts/Document.context';
import { pipe } from 'fp-ts/lib/function';
import * as T from 'fp-ts/lib/Task'; // Import Task utilities
import * as TE from 'fp-ts/lib/TaskEither'; // Import TaskEither utilities
import { useTaskBasedLoadingSetters } from '@contexts/Loading.context'; // For loading, success, and error handling
import { handleLifecycleErrorTask } from '@utils/errorHandlers/lifecycleErrorHandler'; // Import the LifecycleError handler

type DocumentCreationProps = {
    navigation: StackNavigationProp<WorkspaceRoutesParamList, typeof WorkspaceRoutes.DOCUMENT_CREATION>;
    appNavigator: StackNavigationProp<AppStackParamList, typeof AppRoutes.WORKSPACE>;
};

const DocumentCreationPage: React.FC<DocumentCreationProps> = ({ navigation, appNavigator }) => {
    const { lifecycleClient } = useLifecycleClient();
    const { refreshDocuments } = useDocumentListContext();
    const { setDocumentTask } = useDocumentContext();
    const { triggerLoadingState, triggerSuccessState, triggerErrorState } = useTaskBasedLoadingSetters(); // Use task-based loading context setters

    // Task to fetch document and refresh list
    const fetchDocumentAndRefreshTask = (documentID: string): TE.TaskEither<LifecycleError, void> =>
        pipe(
            lifecycleClient.fetchDocument(documentID),
            TE.flatMap(document => TE.rightTask(setDocumentTask(document))),
            TE.flatMap(() => TE.rightTask(refreshDocuments))
        );

    // Task to navigate to document level prompt page
    const navigateToPromptTask = (probingQuestion: string): T.Task<void> =>
        pipe(
            T.fromIO(() =>
                appNavigator.navigate(AppRoutes.WORKSPACE, {
                    screen: WorkspaceRoutes.DOCUMENT_SELECTION
                })),
            T.flatMap(() => T.fromIO(() => {
                appNavigator.navigate(AppRoutes.DOCUMENT, {
                    screen: DocumentRoutes.DOCUMENT_LEVEL_PROMPT,
                    params: { probingQuestion },
                });
            })))

    const handleCreateDocument = (title: string, style: string, outline: string): T.Task<void> => {
        const createDocumentRequest: CreateDocumentRequest = {
            outline,
            style,
            name: title,
        };

        return pipe(
            TE.fromTask(triggerLoadingState), // Start loading when document creation begins
            TE.flatMap(() => lifecycleClient.createDocument(createDocumentRequest)),
            TE.flatMap((response: CreateDocumentResponse) =>
                pipe(
                    fetchDocumentAndRefreshTask(response.document_id), // Fetch document and refresh
                    TE.flatMap(() =>TE.rightTask(navigateToPromptTask(response.prompting_question))),
                    TE.flatMap(() => TE.rightTask(triggerSuccessState))
                )
            ),
            TE.getOrElse((error: LifecycleError) => handleLifecycleErrorTask(error, triggerErrorState))
        );
    };

    return <DocumentCreationComponent onCreateDocumentTask={handleCreateDocument} />;
};

export default DocumentCreationPage;
