import React from 'react';
import { DocumentProvider } from '@contexts/Document.context';
import { NavigationContainer } from '@react-navigation/native';
import AppNavigator from '@navigation/navigator/App.navigator';
import { Provider as PaperProvider } from 'react-native-paper';
import LoadingOverlay from '@components/LoadingOverlay.component';
import {LoadingProvider} from "./src/contexts/Loading.context";
import {AuthenticationClientProvider} from "./src/contexts/AuthenticationClient.context";
import {DocumentListProvider} from "./src/contexts/DocumentList.context";
import {ContentClientProvider} from "./src/contexts/ContentClient.context";
import {LifecycleClientProvider} from "./src/contexts/LifecycleClient.context";
import {JwtProvider} from "./src/contexts/Jwt.context";

const App: React.FC = () => {
    return (
        <PaperProvider>
            <LoadingProvider>
                    <AuthenticationClientProvider>
                        <JwtProvider>
                        <LifecycleClientProvider>
                            <DocumentProvider>
                                        <DocumentListProvider>
                                            <NavigationContainer>
                                                <AppNavigator />
                                                <LoadingOverlay />
                                            </NavigationContainer>
                                        </DocumentListProvider>
                            </DocumentProvider>
                        </LifecycleClientProvider>
                        </JwtProvider>
                    </AuthenticationClientProvider>
            </LoadingProvider>
        </PaperProvider>
    );
};

export default App;
