// @services/stubs/StubLifecycleClient.ts

import { LifecycleClient } from '@services/clients/LifecycleClient';
import { DocumentID } from '@model/documents/core';
import {
    CreateDocumentRequest,
    CreateDocumentResponse,
    ListAvailableDocumentsResponse,
    DocumentFetchResponse,
    OutlineResponse,
    LifecycleError,
} from '@model/clients/LifecycleApi';
import { TaskEither, right } from 'fp-ts/lib/TaskEither';
import {delayTaskEither} from "@utils/delayTaskEither";

export class StubLifecycleClient implements LifecycleClient {
    createDocument(
        request: CreateDocumentRequest
    ): TaskEither<LifecycleError, CreateDocumentResponse> {
        console.log('StubLifecycleClient.createDocument called', request);
        return delayTaskEither(100, right({
            prompting_question: 'What is the purpose of this document?',
            document_id: 'stub-document-id',
        }));
    }

    fetchOutline(documentId: DocumentID): TaskEither<LifecycleError, OutlineResponse> {
        console.log('StubLifecycleClient.fetchOutline called', documentId);
        return delayTaskEither(100, right({
            outline: [
                {
                    id: 'stub-section-id-1',
                    name: 'Introduction',
                    index: 1,
                    status: 'Active',  // Match the exact expected status type
                    last_edited: new Date().toISOString(),
                },
                {
                    id: 'stub-section-id-2',
                    name: 'Conclusion',
                    index: 2,
                    status: 'Unstarted',
                    last_edited: null,
                },
            ],
        }));
    }

    fetchDocument(documentId: DocumentID): TaskEither<LifecycleError, DocumentFetchResponse> {
        console.log('StubLifecycleClient.fetchDocument called', documentId);
        return delayTaskEither(100, right({
            id: documentId,
            name: 'Stub Document',
            document_contents: [
                {
                    section_contents: 'This is the introduction.',
                    metadata: {
                        id: 'stub-section-id-1',
                        name: 'Introduction',
                        index: 1,
                        status: 'Active',
                        last_edited: new Date().toISOString(),
                    },
                },
                {
                    section_contents: 'This is the conclusion.',
                    metadata: {
                        id: 'stub-section-id-2',
                        name: 'Conclusion',
                        index: 2,
                        status: 'Active',
                        last_edited: new Date().toISOString(),
                    },
                },
            ],
        }));
    }

    listAvailableDocuments(): TaskEither<LifecycleError, ListAvailableDocumentsResponse> {
        console.log('StubLifecycleClient.listAvailableDocuments called');
        return delayTaskEither(100, right({
            documents: [
                {
                    id: 'stub-document-id-1',
                    name: 'Stub Document 1',
                    last_edited: new Date().toISOString(),
                    status: 'Active',
                },
                {
                    id: 'stub-document-id-2',
                    name: 'Stub Document 2',
                    last_edited: new Date().toISOString(),
                    status: 'Started',
                },
            ],
        }));
    }
}

