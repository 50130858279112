// @contexts/Document.context.tsx
import React, { createContext, useContext, ReactNode, useMemo, useCallback } from 'react';
import { UserDocument, Section, SectionID, DocumentID } from '@model/documents/core';
import * as T from 'fp-ts/lib/Task'; // Import Task utilities
import { useTaskState } from '@utils/useTaskState';
import { pipe } from 'fp-ts/lib/function';

type DocumentContextType = {
    document: UserDocument | null;
    activeSectionID: SectionID | null;
    setDocumentTask: (doc: UserDocument) => T.Task<void>;
    setActiveSectionIDTask: (id: SectionID) => T.Task<void>;
    updateSectionTask: (updatedSection: Section) => T.Task<void>;
};

type NonNullDocumentContextType = {
    document: UserDocument;
    activeSectionID: SectionID | null;
    setDocumentTask: (doc: UserDocument) => T.Task<void>;
    setActiveSectionIDTask: (id: SectionID) => T.Task<void>;
    updateSectionTask: (updatedSection: Section) => T.Task<void>;
};

const DocumentContext = createContext<DocumentContextType | undefined>(undefined);

type DocumentProviderProps = {
    children: ReactNode;
};

export const DocumentProvider: React.FC<DocumentProviderProps> = ({ children }) => {
    const [document, setDocumentTask] = useTaskState<UserDocument | null>(null);
    const [activeSectionID, setActiveSectionIDTask] = useTaskState<SectionID | null>(null);

    // Update section task
    const updateSectionTask = useCallback(
        (updatedSection: Section): T.Task<void> => {
            if (!document) return T.of(undefined);

            const updatedContents = document.document_contents.map((section) =>
                section.metadata.index === updatedSection.metadata.index
                    ? updatedSection
                    : section
            );

            const newDocument = { ...document, document_contents: updatedContents };

            return setDocumentTask(newDocument);
        },
        [document, setDocumentTask]
    );

    // Memoize the context value to avoid unnecessary re-renders
    const contextValue = useMemo(
        () => ({
            document,
            activeSectionID,
            setDocumentTask,
            setActiveSectionIDTask,
            updateSectionTask,
        }),
        [document, activeSectionID, setDocumentTask, setActiveSectionIDTask, updateSectionTask]
    );

    return <DocumentContext.Provider value={contextValue}>{children}</DocumentContext.Provider>;
};

export const useDocumentContext = (): DocumentContextType => {
    const context = useContext(DocumentContext);
    if (!context) {
        throw new Error('useDocumentContext must be used within a DocumentProvider');
    }
    return context;
};

// This hook guarantees that the document is non-null
export const useNonNullDocumentContext = (): NonNullDocumentContextType => {
    const context = useDocumentContext();
    if (!context.document) {
        throw new Error('Document is not set in DocumentContext');
    }

    return {
        ...context,
        document: context.document as UserDocument, // Assert that document is non-null
    };
};

// Helper hook to get the document ID safely
export const useDocumentID = (): DocumentID => {
    const { document } = useNonNullDocumentContext();
    return document.id;
};
