// @components/writing/review/SectionActions.component.tsx
import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { Button, Card } from 'react-native-paper';
import Clipboard from '@react-native-clipboard/clipboard'; // Import the Clipboard API
import * as T from 'fp-ts/Task';
import { marked } from 'marked'; // Markdown to HTML converter

type SectionActionsProps = {
    sectionContents: string | null;
    handleDraft: T.Task<void>;
    handleRefine: T.Task<void>;
    handleFinalEdits: T.Task<void>;
};

const SectionActions: React.FC<SectionActionsProps> = ({
                                                           sectionContents,
                                                           handleDraft,
                                                           handleRefine,
                                                           handleFinalEdits,
                                                       }) => {
    // Handle copy to clipboard for markdown or HTML
    const handleCopy = async (content: string) => {
        try {
            // Copy to clipboard
            if (Platform.OS === 'web') {
                // Use the browser's clipboard API
                await navigator.clipboard.writeText(content);
                console.log('Copied to clipboard');
            } else {
                // Use the Clipboard API for mobile platforms
                Clipboard.setString(content);
                console.log('Copied to clipboard (mobile)');
            }
        } catch (err) {
            console.error('Failed to copy text: ', err);
            // Optionally, display a user-friendly error message here, e.g. with a toast notification
        }
    };

    return (
        <Card style={styles.actionCard}>
            <Card.Content>
                <View style={styles.buttonContainer}>
                    {!sectionContents && (
                        <Button onPress={() => handleDraft()}>Draft</Button>
                    )}
                    {sectionContents && (
                        <>
                            <Button onPress={() => handleRefine()}>Refine</Button>
                            <Button onPress={() => handleFinalEdits()}>Edit</Button>
                            {/* Add the Copy buttons */}
                            <Button onPress={() => handleCopy(sectionContents)}>Copy Markdown</Button>
                        </>
                    )}
                </View>
            </Card.Content>
        </Card>
    );
};

const styles = StyleSheet.create({
    actionCard: {
        marginTop: -8, // Move the card closer to the section above it
        marginBottom: 8, // Ensure there's still space below
        backgroundColor: '#f9f9f9', // Lighter background color for better contrast
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});

export default SectionActions;
