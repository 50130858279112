// @components/CreditCardInformation.component.tsx

import React from 'react';
import { View } from 'react-native';
import { Button, Title, Card, HelperText } from 'react-native-paper';
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    AddressElement,
} from '@stripe/react-stripe-js';
import * as TE from 'fp-ts/lib/TaskEither';
import { pipe } from 'fp-ts/lib/function';
import { sexyStyles } from '@styles/OuroborosSexyTheme.style';
import { BillingInfo, CardSetupError } from '@model/payments';
import { useTaskBasedLoadingSetters } from '@contexts/Loading.context';
import { handleCardSetupErrorTask } from '@utils/errorHandlers/cardSetupErrorHandler';
import prepareBillingInfo from '@services/CreditCardService';

type CreditCardInformationProps = {
    submitBillingSetup: (billingInfo: BillingInfo) => TE.TaskEither<CardSetupError, void>;
};

const CreditCardInformation: React.FC<CreditCardInformationProps> = ({ submitBillingSetup }) => {
    const elements = useElements();
    const { triggerSuccessState, triggerErrorState, triggerLoadingState } = useTaskBasedLoadingSetters();

    const submitCard = () => {
        pipe(
            TE.fromTask(triggerLoadingState),
            TE.flatMap(() => TE.fromNullable({ type: 'InitializationError' as const } as CardSetupError)(elements)),
            TE.flatMap((stripeElements) => prepareBillingInfo(stripeElements)),
            TE.flatMap((billingInfo) => submitBillingSetup(billingInfo)),
            TE.fold(
                (error) => handleCardSetupErrorTask(error, triggerErrorState),
                () => triggerSuccessState
            ),
        )();
    };

    // Style options for Stripe elements
    const styleOptions = {
        style: {
            base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#9e2146',
            },
        },
    };

    return (
        <View style={sexyStyles.container}>
            <Card style={sexyStyles.card}>
                <Title style={sexyStyles.title}>Payment Information</Title>

                <HelperText type="info">Card Number</HelperText>
                <View style={sexyStyles.input}>
                    <CardNumberElement options={styleOptions} />
                </View>

                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View style={sexyStyles.input}>
                        <HelperText type="info">Expiry Date</HelperText>
                        <CardExpiryElement options={styleOptions} />
                    </View>
                    <View style={sexyStyles.input}>
                        <HelperText type="info">CVC</HelperText>
                        <CardCvcElement options={styleOptions} />
                    </View>
                </View>

                <View style={sexyStyles.input}>
                    <HelperText type="info">Billing Address</HelperText>
                    <AddressElement
                        options={{
                            mode: 'billing',
                        }}
                    />
                </View>

                <Button mode="contained" onPress={submitCard} style={sexyStyles.button}>
                    Save Card Information
                </Button>
            </Card>
        </View>
    );
};

export default CreditCardInformation;
