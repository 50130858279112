// @pages/account/CreateAccount.page.tsx
import React from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import {AuthenticationRoutes, AppRoutes, PaymentRoutes, WorkspaceRoutes} from '@navigation/routes';
import { AuthenticationStackParamList, AppStackParamList } from '@model/navigation';
import { useAuthenticationClient } from '@contexts/AuthenticationClient.context';
import { Email, Password } from '@model/clients/AuthenticationApi';
import { pipe } from 'fp-ts/lib/function';
import { fold, chain as TEChain } from 'fp-ts/lib/TaskEither'; // Chain for TaskEither
import * as T from 'fp-ts/lib/Task'; // Import Task utilities
import { useTaskBasedLoadingSetters } from '@contexts/Loading.context';
import CreateAccountComponent from '@components/authentication/CreateAccount.component';
import { handleAuthenticationErrorTask } from '@utils/errorHandlers/errorHandlers';

type CreateAccountProps = {
    navigation: StackNavigationProp<AuthenticationStackParamList, typeof AuthenticationRoutes.CREATE_ACCOUNT>;
    appNavigator: StackNavigationProp<AppStackParamList, typeof AppRoutes.AUTH>;
};

// Task for navigating to the subscription page after success
const navigateToSubscriptionTask = (appNavigator: StackNavigationProp<AppStackParamList, typeof AppRoutes.AUTH>): T.Task<void> =>
    T.fromIO(() => appNavigator.navigate(AppRoutes.PAYMENT, { screen: PaymentRoutes.CHOOSE_SUBSCRIPTION,  params: {subscription_status: 'PaymentNotYetConfigured'}}));

const CreateAccountPage: React.FC<CreateAccountProps> = ({ appNavigator }) => {
    const { authenticationClient } = useAuthenticationClient();
    const { triggerLoadingState, triggerSuccessState, triggerErrorState } = useTaskBasedLoadingSetters();

    // Task for handling the success flow (trigger success + navigate)
    const handleSuccessTask = pipe(
        triggerSuccessState,
        T.chain(() => navigateToSubscriptionTask(appNavigator))
    );

    // Task to create account, with named task handlers for success and error
    const createAccountTask = (email: Email, password: Password): T.Task<void> =>
        pipe(
            triggerLoadingState, // Trigger loading state
            T.chain(() => authenticationClient.createAccount({ email, password })), // Attempt to create account
            fold(
                (error) => handleAuthenticationErrorTask(error, triggerErrorState), // Handle error case
                () => handleSuccessTask // Handle success case
            )
        );

    return <CreateAccountComponent onCreateAccount={createAccountTask} />;
};

export default CreateAccountPage;
