// @navigation/navigators/Payment.navigator.tsx
import React from 'react';
import {createStackNavigator, StackNavigationProp} from '@react-navigation/stack';
import {AppStackParamList, PaymentStackParamList} from '@model/navigation';
import ChooseSubscriptionPage from '@pages/payment/ChooseSubscription.page';
import PaymentSetupPage from '@pages/payment/PaymentSetup.page';
import {AppRoutes, PaymentRoutes, WorkspaceRoutes} from '@navigation/routes';
import { PaymentClientProvider } from '@contexts/PaymentClient.context';
import CreateAccountPage from "@pages/account/CreateAccount.page";
import DocumentCreationPage from "@pages/workspace/DocumentCreation.page";
import {View} from "react-native";

const Stack = createStackNavigator<PaymentStackParamList>();

type PaymentNavigatorProps = {
    navigation: StackNavigationProp<AppStackParamList, typeof AppRoutes.PAYMENT>;
};
const PaymentNavigator: React.FC<PaymentNavigatorProps> = ({navigation}) => {
    return (
        <PaymentClientProvider>
            <Stack.Navigator
                initialRouteName={PaymentRoutes.CHOOSE_SUBSCRIPTION}
                screenOptions={{
                    cardStyle: { backgroundColor: '#053A3A' },
                    cardOverlay: () => (
                        <View
                            style={{
                                flex: 1,
                                backgroundColor: '#053A3A',
                            }}
                        />
                    ),
                    detachPreviousScreen: false
                }}
            >
                <Stack.Screen
                    name={PaymentRoutes.CHOOSE_SUBSCRIPTION}
                    options={{ headerShown: false }}
                >
                    {props => <ChooseSubscriptionPage {...props} appNavigator={navigation} />}
                </Stack.Screen>
                <Stack.Screen
                    name={PaymentRoutes.PAYMENT_SETUP}
                    options={{ headerShown: false }}
                >
                    {props => <PaymentSetupPage {...props} appNavigator={navigation} />}
                </Stack.Screen>
            </Stack.Navigator>
        </PaymentClientProvider>
    );
};

export default PaymentNavigator;
