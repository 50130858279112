// @pages/document/DocumentDetailsPrompt.page.tsx

import React from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { useRoute } from '@react-navigation/native';
import { DocumentRoutes } from '@navigation/routes';
import { DocumentRoutesParamList } from '@model/navigation';
import DocumentDetailsPromptComponent from '@components/document/DocumentDetailsPrompt.component';
import { Markdown } from '@model/markdown';
import { useContentClient } from '@contexts/ContentClient.context';
import { useDocumentID } from '@contexts/Document.context';
import { pipe } from 'fp-ts/lib/function';
import { fold } from 'fp-ts/lib/TaskEither';
import * as T from 'fp-ts/lib/Task';
import { useTaskBasedLoadingSetters } from '@contexts/Loading.context'; // For task-based loading, success, and error handling
import { handleContentErrorTask } from '@utils/errorHandlers/contentErrorHandler'; // Updated ContentError handler to return a Task
import { ContentClient } from '@services/clients/ContentClient';

type DocumentDetailsPromptProps = {
    navigation: StackNavigationProp<DocumentRoutesParamList, typeof DocumentRoutes.DOCUMENT_LEVEL_PROMPT>;
};

// Task for navigating to the review page
const navigateToReviewTask = (
    navigation: StackNavigationProp<DocumentRoutesParamList, typeof DocumentRoutes.DOCUMENT_LEVEL_PROMPT>
): T.Task<void> =>
    T.fromIO(() => navigation.navigate(DocumentRoutes.REVIEW));

// Task to trigger success state and navigate to the review page
const handleSuccessTask = (
    triggerSuccessState: T.Task<void>,
    navigation: StackNavigationProp<DocumentRoutesParamList, typeof DocumentRoutes.DOCUMENT_LEVEL_PROMPT>
): T.Task<void> =>
    pipe(
        triggerSuccessState, // Set success state
        T.chain(() => navigateToReviewTask(navigation)) // Navigate to the review page
    );

// Task to submit prompt and handle success/error states
const submitPromptTask = (
    userInput: string,
    documentID: string,
    contentClient: ContentClient,
    triggerLoadingState: T.Task<void>,
    triggerSuccessState: T.Task<void>,
    triggerErrorState: (message: string) => T.Task<void>,
    navigation: StackNavigationProp<DocumentRoutesParamList, typeof DocumentRoutes.DOCUMENT_LEVEL_PROMPT>
): T.Task<void> =>
    pipe(
        triggerLoadingState, // Start loading state
        T.chain(() =>
            pipe(
                contentClient.answerDocumentQuestions(documentID, { answers: userInput }), // Submit the answers
                fold(
                    (error) => handleContentErrorTask(error, triggerErrorState), // Handle error
                    () => handleSuccessTask(triggerSuccessState, navigation) // Handle success and navigate
                )
            )
        )
    );

const DocumentDetailsPromptPage: React.FC<DocumentDetailsPromptProps> = ({ navigation }) => {
    const { contentClient } = useContentClient();
    const route = useRoute();
    const { probingQuestion } = route.params as { probingQuestion: Markdown };
    const documentID = useDocumentID();
    const { triggerLoadingState, triggerSuccessState, triggerErrorState } = useTaskBasedLoadingSetters(); // Task-based loading context setters

    const handlePromptSubmit = (userInput: string): T.Task<void> =>
        submitPromptTask(
            userInput,
            documentID,
            contentClient,
            triggerLoadingState,
            triggerSuccessState,
            triggerErrorState,
            navigation
        );

    return <DocumentDetailsPromptComponent onSubmitTask={handlePromptSubmit} documentQuestion={probingQuestion} />;
};

export default DocumentDetailsPromptPage;
