// @pages/account/Login.page.tsx
import React from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { AuthenticationRoutes, AppRoutes, WorkspaceRoutes } from '@navigation/routes';
import { AuthenticationStackParamList, AppStackParamList } from '@model/navigation';
import { useAuthenticationClient } from '@contexts/AuthenticationClient.context';
import CreateForgotPasswordComponent from '@components/authentication/ForgotPassword.component';
import {Email} from '@model/clients/AuthenticationApi'

type ForgotPasswordProps = {
    navigation: StackNavigationProp<AuthenticationStackParamList, typeof AuthenticationRoutes.FORGOT_PASSWORD>;
};

const ForgotPasswordPage: React.FC<ForgotPasswordProps> = ({ navigation }) => {
    const { authenticationClient } = useAuthenticationClient(); // Use the account client context

    const handleForgotPasswordSubmit = async (email: Email) => {
        try {
            // TODO() Implement accountClient.forgotPassword(email)
            //  await accountClient.createAccount({  password, email });
            // appNavigator.navigate(AppRoutes.WORKSPACE, {
            //     screen: WorkspaceRoutes.DOCUMENT_SELECTION,
            // });
        } catch (error) {
            console.error('Account creation failed', error);
        }
    };

    return <CreateForgotPasswordComponent onForgotPasswordSubmit={handleForgotPasswordSubmit}/>
};

export default ForgotPasswordPage;
