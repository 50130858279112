import React from 'react';
import { View, Text, Button, ScrollView } from 'react-native';
import { DocumentMetadata } from '@model/documents/core';
import { DocumentSelectionStyles as styles } from '@styles/DocumentSelection.style';
import { enabledFeatures, FeaturesEnum } from '@utils/enabledFeatures';
import * as T from 'fp-ts/lib/Task'; // Import Task utilities

export type DocumentSelectionComponentProps = {
    onCreateDocumentTask: T.Task<void>; // Task for creating a document
    onSelectDocumentTask: (documentID: string) => T.Task<void>; // Task for selecting a document
    availableDocuments: DocumentMetadata[];
    onLogoutTask: T.Task<void>; // Task for logging out
    handleSubscriptionCancelTask: T.Task<void>
};

const DocumentSelectionComponent: React.FC<DocumentSelectionComponentProps> = ({
                                                                                   onCreateDocumentTask,
                                                                                   onSelectDocumentTask,
                                                                                   availableDocuments,
                                                                                   onLogoutTask,
                                                                                   handleSubscriptionCancelTask
                                                                               }) => {

    return (
        <View style={styles.container}>
            <Text style={styles.text}>Document Selection Page</Text>
            <ScrollView style={styles.documentList}>
                {availableDocuments.map((doc) => (
                    <View key={doc.id} style={styles.documentItem}>
                        <Text style={styles.documentName}>{doc.name}</Text>
                        <Button title="Select" onPress={() => onSelectDocumentTask(doc.id)()} />
                    </View>
                ))}
            </ScrollView>
            <Button title="Create New Document" onPress={() => onCreateDocumentTask()} />

            {/* Conditionally render the Logout button if the feature flag is enabled */}
            {enabledFeatures[FeaturesEnum.SignOutButton] && (
                <Button title="Logout" onPress={() => onLogoutTask()} />
            )}
            <Button title="Cancel Subscription" onPress={() => handleSubscriptionCancelTask()} />
        </View>
    );
};

export default DocumentSelectionComponent;
