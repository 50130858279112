import React from 'react';
import { View, StyleSheet, ActivityIndicator, Text, Button } from 'react-native';
import { Modal, Portal, useTheme } from 'react-native-paper';
import { useLoadingState, useLoadingError, useTaskBasedLoadingSetters } from '@contexts/Loading.context';
import { enabledFeatures, FeaturesEnum } from '@src/utils/enabledFeatures';
import OuroborosSpinnerComponent from './OuroborosSpinner.component';
import { sexyStyles } from '@src/styles/OuroborosSexyTheme.style';
import * as T from 'fp-ts/lib/Task'; // Import Task utilities

const LoadingOverlay: React.FC = () => {
    const state = useLoadingState();
    const { errorMessage, additionalInfo } = useLoadingError();
    const { triggerIdleState } = useTaskBasedLoadingSetters(); // Task-based setter for idle state
    const theme = useTheme();

    return (
        <Portal>
            <Modal visible={(state === 'loading' || state === 'error')} contentContainerStyle={{ shadowColor: 'transparent' }}>
                <View style={sexyStyles.loading}>
                    {state === 'loading' && (
                        enabledFeatures[FeaturesEnum.SexySpinner] ? (
                            <OuroborosSpinnerComponent />
                        ) : (
                            <ActivityIndicator size="large" color={theme.colors.primary} />
                        )
                    )}
                    {state === 'error' && (
                        <View style={styles.errorContainer}>
                            <Text style={styles.errorText}>{errorMessage}</Text>
                            {additionalInfo && <Text style={styles.additionalInfoText}>{additionalInfo}</Text>}
                            <Button title="Dismiss" onPress={() => triggerIdleState()} color={theme.colors.primary} />
                        </View>
                    )}
                </View>
            </Modal>
        </Portal>
    );
};

const styles = StyleSheet.create({
    errorContainer: {
        alignItems: 'center',
        padding: 20,
    },
    errorText: {
        color: 'white',
        marginBottom: 10,
        textAlign: 'center',
    },
    additionalInfoText: {
        color: 'lightgrey',
        marginBottom: 20,
        textAlign: 'center',
    },
});

export default LoadingOverlay;
