// @pages/document/Prompt.page.tsx
import React from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { useRoute } from '@react-navigation/native';
import { DocumentRoutes } from '@navigation/routes';
import { DocumentRoutesParamList } from '@model/navigation';
import PromptComponent from '@components/document/Prompt.component';
import { Markdown } from '@model/markdown';
import { useContentClient } from '@contexts/ContentClient.context';
import { useNonNullDocumentContext } from '@contexts/Document.context';
import { pipe } from 'fp-ts/lib/function';
import { fold } from 'fp-ts/lib/TaskEither';
import * as T from 'fp-ts/lib/Task'; // Import Task utilities
import { useTaskBasedLoadingSetters } from '@contexts/Loading.context'; // Use task-based loading context setters
import { handleContentErrorTask } from '@utils/errorHandlers/contentErrorHandler';
import {Section} from "@model/documents/core"; // Task-based ContentError handler

type PromptProps = {
    navigation: StackNavigationProp<DocumentRoutesParamList, typeof DocumentRoutes.PROMPT>;
};

const PromptPage: React.FC<PromptProps> = ({ navigation }) => {
    const { contentClient } = useContentClient();
    const { document, updateSectionTask, activeSectionID } = useNonNullDocumentContext();
    const route = useRoute();
    const { probingQuestion } = route.params as { probingQuestion: Markdown };
    const { triggerLoadingState, triggerSuccessState, triggerErrorState } = useTaskBasedLoadingSetters(); // Use task-based loading context setters

    // Task to navigate to the review page
    const navigateToReviewTask: T.Task<void> =
        T.fromIO(() => navigation.navigate(DocumentRoutes.REVIEW));

    // Task to handle success case (updating section and navigating)
    const handleSuccessTask = (updatedSection: Section): T.Task<void> =>
        pipe(
            updateSectionTask(updatedSection), // Update section
            T.flatMap(() => navigateToReviewTask), // Navigate to review page
            T.flatMap(() => triggerSuccessState) // Set success after navigating
        );

    const handlePromptSubmit = (userInput: string): T.Task<void> => {
        if (document && activeSectionID) {
            return pipe(
                triggerLoadingState, // Set loading state
                T.flatMap(() =>
                    pipe(
                        contentClient.answerSectionQuestions(document.id, {
                            answers: userInput,
                            section_id: activeSectionID,
                        }),
                        fold(
                            (error) => handleContentErrorTask(error, triggerErrorState), // Handle error case
                            (response) => handleSuccessTask(response.updated_section) // Handle success
                        )
                    )
                )
            );
        } else {
            console.error('Document or Section ID is missing');
            return T.of(undefined); // Return no-op task
        }
    };

    return <PromptComponent probingQuestion={probingQuestion} onSubmitTask={handlePromptSubmit} />;
};

export default PromptPage;
