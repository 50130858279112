import React, { useEffect } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import {AuthenticationRoutes, AppRoutes, WorkspaceRoutes, PaymentRoutes} from '@navigation/routes';
import { AuthenticationStackParamList, AppStackParamList } from '@model/navigation';
import { useAuthenticationClient } from '@contexts/AuthenticationClient.context';
import { useJwt } from '@contexts/Jwt.context';
import SexyLoginComponent from '@components/authentication/Login.component';
import {Email, Password, SubscriptionStatus} from '@model/clients/AuthenticationApi';
import { pipe } from 'fp-ts/lib/function';
import { fold } from 'fp-ts/lib/TaskEither';
import * as T from 'fp-ts/lib/Task';
import { AuthenticationError } from '@model/clients/AuthenticationApi';
import { useTaskBasedLoadingSetters } from '@contexts/Loading.context';
import { handleAuthenticationErrorTask } from '@utils/errorHandlers/errorHandlers';

type LoginProps = {
    navigation: StackNavigationProp<AuthenticationStackParamList, typeof AuthenticationRoutes.LOGIN>;
    appNavigator: StackNavigationProp<AppStackParamList, typeof AppRoutes.AUTH>;
};

// Task for navigating to document selection
const navigateToDocumentSelectionTask = (
    appNavigator: StackNavigationProp<AppStackParamList, typeof AppRoutes.AUTH>
): T.Task<void> =>
    T.fromIO(() =>
        appNavigator.navigate(AppRoutes.WORKSPACE, { screen: WorkspaceRoutes.DOCUMENT_SELECTION })
    );


const navigateToPaymentSetupTask = (
    appNavigator: StackNavigationProp<AppStackParamList, typeof AppRoutes.AUTH>,
    subscriptionStatus: SubscriptionStatus
): T.Task<void> =>
    T.fromIO(() =>
        appNavigator.navigate(AppRoutes.PAYMENT, { screen: PaymentRoutes.CHOOSE_SUBSCRIPTION, params: {subscription_status: subscriptionStatus} })
    );
// Task for handling login success and triggering success state
const handleLoginSuccessTask = (
    triggerSuccessState: T.Task<void>,
    appNavigator: StackNavigationProp<AppStackParamList, typeof AppRoutes.AUTH>,
    subscriptionStatus: SubscriptionStatus
): T.Task<void> =>
    pipe(
        triggerSuccessState,
        T.chain(() =>
            subscriptionStatus === 'FreeTrialMode' || subscriptionStatus === 'Paid'
                ? navigateToDocumentSelectionTask(appNavigator)
                : navigateToPaymentSetupTask(appNavigator, subscriptionStatus)
        )
    );


// Task for handling account creation navigation
const handleCreateAccountTask = (
    navigation: StackNavigationProp<AuthenticationStackParamList, typeof AuthenticationRoutes.LOGIN>
): T.Task<void> => T.fromIO(() => navigation.navigate(AuthenticationRoutes.CREATE_ACCOUNT));

const LoginPage: React.FC<LoginProps> = ({ navigation, appNavigator }) => {
    const { authenticationClient } = useAuthenticationClient();
    const { isTokenExpiring, refreshToken, subscriptionStatus } = useJwt();
    const { triggerLoadingState, triggerSuccessState, triggerErrorState } = useTaskBasedLoadingSetters();

    useEffect(() => {
        void refreshToken(); //Ignores errors in a suspicious way
    }, [refreshToken]);

    useEffect(() => {
        if (!isTokenExpiring && (subscriptionStatus === 'FreeTrialMode' || subscriptionStatus === 'Paid')) {
            void navigateToDocumentSelectionTask(appNavigator)();
        }
    }, [isTokenExpiring, appNavigator]);

    const handleLogin = (email: Email, password: Password): T.Task<void> =>
        pipe(
            triggerLoadingState,
            T.chain(() =>
                pipe(
                    authenticationClient.login({ email, password }),
                    fold(
                        (error: AuthenticationError) => handleAuthenticationErrorTask(error, triggerErrorState),
                        (subscriptionStatus) => handleLoginSuccessTask(triggerSuccessState, appNavigator, subscriptionStatus)
                    )
                )
            )
        );

    return (
        <>
            <SexyLoginComponent
                onLogin={handleLogin}
                onCreateAccount={handleCreateAccountTask(navigation)}
            />
        </>
    );
};

export default LoginPage;
