// @components/writing/review/SectionCard.component.tsx
import React from 'react';
import {Card, Text, TouchableRipple} from 'react-native-paper';
import { StyleSheet, View } from 'react-native';
import { Section as SectionType } from '@model/documents/core';
import Markdown from 'react-native-markdown-display';
import * as T from 'fp-ts/lib/Task'; // Import Task utilities

type SectionCardProps = {
    section: SectionType;
    active: boolean;
    onPressTask: T.Task<void>; // Task-based onPress
};

const SectionCard: React.FC<SectionCardProps> = ({ section, active, onPressTask }) => {
    const handlePress = () => {
        void onPressTask(); // Execute the task when pressed
    };

    const InnerCard = (
        <Card style={[styles.card, active && styles.activeCard]}>
            <Card.Title title={section.metadata.name} />
            <Card.Content>
                {section.section_contents ? (
                    <Markdown>{section.section_contents}</Markdown>
                ) : (
                    <Text>
                        This section hasn't been started, tap or click this text, then the button that appears to
                        get started.
                    </Text>
                )}
            </Card.Content>
        </Card>
    );

    return active ? (
        // Render the card directly when active (no TouchableRipple)
        <View>{InnerCard}</View>
    ) : (
        // Wrap the card in TouchableRipple when inactive
        <TouchableRipple
            onPress={handlePress}
            accessibilityRole="button"
            accessibilityLabel={`${section.metadata.name}`}
            accessibilityState={{ expanded: active }}
        >
            {InnerCard}
        </TouchableRipple>
    );
};

const styles = StyleSheet.create({
    card: {
        marginVertical: 8,
    },
    activeCard: {
        borderColor: '#6200ee', // Example color for active section
        borderWidth: 2,
    },
});

export default React.memo(SectionCard, (prevProps, nextProps) => {
    return prevProps.active === nextProps.active && prevProps.section === nextProps.section;
});
