// @contexts/PaymentClient.context.tsx

import React, { createContext, useContext, ReactNode, useMemo, useEffect } from 'react';
import { PaymentClient } from '@services/clients/PaymentClient';
import { DefaultPaymentClient } from '@services/ouroboros/DefaultPaymentClient';
import { StubPaymentClient } from '@services/stubs/StubPaymentClient';
import { useTaskBasedLoadingSetters } from '@contexts/Loading.context';
import { enabledFeatures, FeaturesEnum } from '@src/utils/enabledFeatures';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/lib/TaskEither';
import * as T from 'fp-ts/lib/Task';
import { useTaskState } from '@utils/useTaskState';

interface PaymentClientContextType {
    paymentClient: PaymentClient;
}

const PaymentClientContext = createContext<PaymentClientContextType | undefined>(undefined);

export const PaymentClientProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [stripe, setStripeTask] = useTaskState<Stripe | null>(null);
    const { triggerLoadingState, triggerSuccessState, triggerErrorState } = useTaskBasedLoadingSetters();

    // Memoize the creation of the payment client
    const paymentClient = useMemo<PaymentClient>(() => {
        return enabledFeatures[FeaturesEnum.StubClients]
            ? new StubPaymentClient() // Stub client if enabled
            : new DefaultPaymentClient(); // Default client otherwise
    }, []);

    // Fetch publishable key and initialize Stripe
    const fetchPublishableKeyTask: T.Task<void> = useMemo(
        () =>
            pipe(
                triggerLoadingState,
                T.flatMap(() => paymentClient.getPublishableKey()),
                TE.flatMap((key) => TE.tryCatch(
                    () => loadStripe(key), // Returns Promise<Stripe | null>
                    () => new Error('Failed to load Stripe')
                )),
                TE.flatMap((stripeInstance: Stripe | null) => TE.rightTask(setStripeTask(stripeInstance))),
                TE.fold(
                    (error) => triggerErrorState('Failed to fetch publishable key', error.message),
                    () => triggerSuccessState
                )
            ),
        [paymentClient, triggerLoadingState, triggerErrorState, triggerSuccessState, setStripeTask]
    );

    // Run the Stripe initialization task when the component mounts
    useEffect(() => {
        void fetchPublishableKeyTask(); // Execute the Task without returning anything
    }, [fetchPublishableKeyTask]);

    // Memoize the context value to prevent unnecessary re-renders
    const contextValue = useMemo(
        () => ({
            paymentClient,
        }),
        [paymentClient]
    );

    if (!stripe) {
        // Optionally show a loading state or spinner
        return null;
    }

    return (
        <PaymentClientContext.Provider value={contextValue}>
            <Elements stripe={stripe}>
                {children}
            </Elements>
        </PaymentClientContext.Provider>
    );
};

export const usePaymentClient = (): PaymentClientContextType => {
    const context = useContext(PaymentClientContext);
    if (!context) {
        throw new Error('usePaymentClient must be used within a PaymentClientProvider');
    }
    return context;
};
