// @services/stubs/StubContentClient.ts

import { ContentClient } from '@services/clients/ContentClient';
import { DocumentID, Section } from '@model/documents/core';
import {
    ReplaceSectionRequest,
    ReplaceSectionResponse,
    AnswerSectionQuestionsRequest,
    AnswerSectionQuestionsResponse,
    GenerateSectionQuestionsRequest,
    GenerateSectionQuestionsResponse,
    RefineRequest,
    RefineResponse,
    AnswerDocumentQuestionsRequest,
    GenerateDocumentQuestionsResponse,
    ContentError,
} from '@model/clients/ContentApi';
import { TaskEither, right } from 'fp-ts/lib/TaskEither';
import { delayTaskEither } from '@utils/delayTaskEither'; // Assume this helper exists

export class StubContentClient implements ContentClient {
    refineSection(
        documentId: DocumentID,
        request: RefineRequest
    ): TaskEither<ContentError, RefineResponse> {
        console.log('StubContentClient.refineSection called', documentId, request);
        return delayTaskEither(100, right({
            updated_section: {
                section_contents: request.feedback + '\n\nRefined content.',
                metadata: {
                    id: request.section_id,
                    name: 'Sample Section',
                    index: 1,
                    status: 'Active',
                    last_edited: new Date().toISOString(),
                },
            },
        }));
    }

    replaceSection(
        documentId: DocumentID,
        request: ReplaceSectionRequest
    ): TaskEither<ContentError, ReplaceSectionResponse> {
        console.log('StubContentClient.replaceSection called', documentId, request);
        return delayTaskEither(100, right({
            updated_section: {
                section_contents: request.edits,
                metadata: {
                    id: request.section_id,
                    name: 'Sample Section',
                    index: 1,
                    status: 'Active',
                    last_edited: new Date().toISOString(),
                },
            },
        }));
    }

    answerSectionQuestions(
        documentId: DocumentID,
        request: AnswerSectionQuestionsRequest
    ): TaskEither<ContentError, AnswerSectionQuestionsResponse> {
        console.log('StubContentClient.answerSectionQuestions called', documentId, request);
        return delayTaskEither(100, right({
            updated_section: {
                section_contents: request.answers + '\n\nAnswered questions.',
                metadata: {
                    id: request.section_id,
                    name: 'Sample Section',
                    index: 1,
                    status: 'Active',
                    last_edited: new Date().toISOString(),
                },
            },
        }));
    }

    generateSectionQuestions(
        documentId: DocumentID,
        request: GenerateSectionQuestionsRequest
    ): TaskEither<ContentError, GenerateSectionQuestionsResponse> {
        console.log('StubContentClient.generateSectionQuestions called', documentId, request);
        return delayTaskEither(100, right({
            probing_questions: 'What is the main point of this section?\n\nWhat supporting details can be added?',
        }));
    }

    answerDocumentQuestions(
        documentId: DocumentID,
        request: AnswerDocumentQuestionsRequest
    ): TaskEither<ContentError, void> {
        console.log('StubContentClient.answerDocumentQuestions called', documentId, request);
        return delayTaskEither(100, right(undefined));
    }

    generateDocumentQuestions(
        documentId: DocumentID
    ): TaskEither<ContentError, GenerateDocumentQuestionsResponse> {
        console.log('StubContentClient.generateDocumentQuestions called', documentId);
        return delayTaskEither(100, right({
            probing_questions: 'What is the purpose of this document?\n\nWhat are the key takeaways?',
        }));
    }
}

