// @components/Prompt.component.tsx
import React, {useState} from 'react';
import { View, Text, TextInput, Button, ScrollView } from 'react-native';
import { PromptStyles as styles } from '@styles/Prompt.style';
import { Markdown as MarkdownType } from '@model/markdown';
import Markdown from 'react-native-markdown-display';
import * as T from 'fp-ts/lib/Task'; // Import Task utilities
import { pipe } from 'fp-ts/lib/function';
import { useTaskState } from '@utils/useTaskState';

type PromptComponentProps = {
    probingQuestion: MarkdownType;
    onSubmitTask: (userInput: string) => T.Task<void>; // Task-based onSubmit
};

const PromptComponent: React.FC<PromptComponentProps> = ({ probingQuestion, onSubmitTask }) => {
    const [userInput, setUserInput] = useState<string>('');

    const handleOnSubmit = () => {
        void pipe(
            onSubmitTask(userInput),
            T.chain(() => T.fromIO(() => setUserInput(''))) // Clear the input field after submission
        )();
    };

    return (
        <View style={styles.container}>
            <Text style={styles.header}>Probing Questions</Text>
            <ScrollView style={styles.probingQuestionContainer}>
                <Markdown>{probingQuestion}</Markdown>
            </ScrollView>
            <TextInput
                style={styles.input}
                value={userInput}
                onChangeText={setUserInput}
                placeholder="Type your answer here..."
                multiline={true}
            />
            <Button title="Submit" onPress={handleOnSubmit} />
        </View>
    );
};

export default PromptComponent;
