// @components/FinalEdits.component.tsx
import React from 'react';
import { View, Text, TextInput, Button } from 'react-native';
import { FinalEditsStyles as styles } from '@styles/FinalEdits.style';
import * as T from 'fp-ts/lib/Task';

type FinalEditsComponentProps = {
    finalEdits: string;
    setFinalEdits: (text: string) => void;
    onSubmitTask: T.Task<void>;
};

const FinalEditsComponent: React.FC<FinalEditsComponentProps> = ({
                                                                     finalEdits,
                                                                     setFinalEdits,
                                                                     onSubmitTask,
                                                                 }) => {
    return (
        <View style={styles.container}>
            <Text style={styles.header}>Manual Edits</Text>
            <TextInput
                style={styles.input}
                value={finalEdits}
                onChangeText={setFinalEdits} // Execute the task immediately
                placeholder="Enter your edits here, the AI will be notified about your changes."
                multiline
            />
            <Button title="Submit Edits" onPress={() => void onSubmitTask()} />
        </View>
    );
};

export default FinalEditsComponent;
