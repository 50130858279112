// @navigation/navigators/Authentication.navigator.tsx
import React from 'react';
import { createStackNavigator, StackNavigationProp } from '@react-navigation/stack';
import { AuthenticationStackParamList, AppStackParamList } from '@model/navigation';
import LoginPage from '@pages/account/Login.page';
import CreateAccountPage from '@pages/account/CreateAccount.page';
import { AppRoutes, AuthenticationRoutes } from '@navigation/routes';
import ForgotPasswordPage from '@pages/account/ForgotPassword.page';

const Stack = createStackNavigator<AuthenticationStackParamList>();

type AuthenticationNavigatorProps = {
    navigation: StackNavigationProp<AppStackParamList, typeof AppRoutes.AUTH>;
};

const AuthenticationNavigator: React.FC<AuthenticationNavigatorProps> = ({ navigation }) => {
    return (
        <Stack.Navigator
            initialRouteName={AuthenticationRoutes.LOGIN}
            screenOptions={{
                headerShown: true,
                cardStyle: { backgroundColor: 'transparent' },
                detachPreviousScreen: false,
            }}
        >
            <Stack.Screen name={AuthenticationRoutes.LOGIN}>
                {props => <LoginPage {...props} appNavigator={navigation} />}
            </Stack.Screen>

            <Stack.Screen name={AuthenticationRoutes.CREATE_ACCOUNT}>
                {props => <CreateAccountPage {...props} appNavigator={navigation} />}
            </Stack.Screen>
            <Stack.Screen name={AuthenticationRoutes.FORGOT_PASSWORD} component={ForgotPasswordPage} />
        </Stack.Navigator>
    );
};

export default AuthenticationNavigator;
