// @components/workspaces/DocumentCreation.component.tsx

import React, { useState } from 'react'; // Using useState instead of useTaskState
import { View, TextInput, Button } from 'react-native';
import { DocumentCreationStyles as styles } from '@styles/DocumentCreation.style';
import * as T from 'fp-ts/lib/Task'; // Task utilities remain for task-based logic

type DocumentCreationComponentProps = {
    onCreateDocumentTask: (title: string, style: string, outline: string) => T.Task<void>; // Task-based function
};

const DocumentCreationComponent: React.FC<DocumentCreationComponentProps> = ({ onCreateDocumentTask }) => {
    const [title, setTitleImpure] = useState(''); // Standard useState with renamed setter
    const [style, setStyleImpure] = useState(''); // Standard useState with renamed setter
    const [outline, setOutlineImpure] = useState(''); // Standard useState with renamed setter

    const handleCreateDocument = (): void => {
        void onCreateDocumentTask(title, style, outline)(); // Execute the task when the button is pressed
    };

    return (
        <View style={styles.container}>
            <TextInput
                style={styles.input}
                placeholder="Title"
                value={title}
                onChangeText={setTitleImpure} // Direct use of the useState setter
            />
            <TextInput
                style={styles.multiline}
                placeholder="Explain your intentions for the document and any style or tone guidelines. You can also provide examples or drafts."
                value={style}
                onChangeText={setStyleImpure} // Direct use of the useState setter
                multiline={true}
            />
            <TextInput
                style={styles.multiline}
                placeholder="Please write your outline with one section title per line, you need at least one section. Avoid using bullets or numbering. DO NOT try to use this space to supply additional instructions to the AI"
                value={outline}
                onChangeText={setOutlineImpure} // Direct use of the useState setter
                multiline={true}
            />
            <Button title="Create Document" onPress={handleCreateDocument} />
        </View>
    );
};

export default DocumentCreationComponent;
