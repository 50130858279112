// @components/writing/review/SectionList.component.tsx
import React from 'react';
import { FlatList } from 'react-native';
import { SectionID } from '@model/documents/core';
import { useNonNullDocumentContext } from '@contexts/Document.context';
import Section from './Section.component';
import * as T from 'fp-ts/lib/Task';
import { UserSectionReplacement } from "@model/clients/ContentApi"; // Import Task utilities

type SectionListProps = {
    handleRefine: T.Task<void>; // Task-based handler for refining
    handleEdits: T.Task<void>;
    handleDraft: T.Task<void>; // Task-based handler for drafting
};

const SectionList: React.FC<SectionListProps> = ({
                                                     handleRefine,
                                                     handleEdits,
                                                     handleDraft,
                                                 }) => {
    const { document, activeSectionID, setActiveSectionIDTask } = useNonNullDocumentContext();

    // Sort sections by metadata.index
    const sortedSections = [...document.document_contents].sort((a, b) => a.metadata.index - b.metadata.index);

    return (
        <FlatList
            data={sortedSections} // Use sorted data
            renderItem={({ item }) => (
                <Section
                    section={item}
                    active={activeSectionID === item.metadata.id}
                    handleRefine={handleRefine}
                    handleFinalEdits={handleEdits}
                    handleDraft={handleDraft}
                    setActiveSection={setActiveSectionIDTask} // Task-based setter
                />
            )}
            keyExtractor={(item) => item.metadata.id}
            extraData={activeSectionID} // Rerender only when active section changes
        />
    );
};

export default SectionList;
