// @utils/cardSetupErrorHandler.ts

import { ContentError } from '@model/clients/ContentApi';
import * as T from 'fp-ts/lib/Task';
import {CardSetupError} from '@model/payments'

export const handleCardSetupErrorTask = (
    error: CardSetupError,
    setError: (message: string) => T.Task<void>
): T.Task<void> => {
    switch (error.type) {
        case 'InitializationError':
            return setError("Stripe didn't load correctly, refresh the page or try again later.");
        case 'StripeConnectionError':
            return setError("Your payment was submitted but couldn't be confirmed, please try again later.");
        case 'AddressElementError':
            return setError(`There was an issue parsing your billing information, please review it or try again later.`);
        case 'CardElementError':
            return setError(`There was an issue parsing your card information, please review it or try again later.`);
    }
};
