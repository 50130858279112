// @services/ouroboros/DefaultPaymentClient.ts

import {
    StripeSetupIntentSecret,
    StripePublishableKey,
    GetPublishableKeyResponse,
    SetupIntentResponse,
    PaymentError, SubscriptionPlan,
} from '@model/clients/PaymentApi';
import API_URL from '@utils/config';
import { PaymentClient } from '@services/clients/PaymentClient';
import * as t from 'io-ts';
import { v7 as uuidv7 } from 'uuid';
import { TaskEither, left, right, tryCatch, chain, map } from 'fp-ts/lib/TaskEither';
import { pipe } from 'fp-ts/lib/function';

export class DefaultPaymentClient implements PaymentClient {
    constructor() {
        // No dependencies
    }

    private generateTraceId(): string {
        return uuidv7();
    }

    private fetchWithTrace(url: string, options: RequestInit): TaskEither<PaymentError, Response> {
        const traceId = this.generateTraceId();
        options.headers = {
            ...options.headers,
            'X-Trace-ID': traceId,
        };
        options.credentials = 'include'; // Ensure credentials are included

        return tryCatch(
            async () => await fetch(url, options),
            (error) =>
                ({
                    type: 'NetworkError',
                    message: 'Failed to connect to the server. Please check your internet connection.',
                } as PaymentError)
        );
    }

    private validateResponse<T>(
        response: Response,
        validator: t.Type<T>
    ): TaskEither<PaymentError, T> {
        return tryCatch(
            async () => {
                const data = await response.json();
                const decoded = validator.decode(data);
                if (decoded._tag === 'Right') {
                    return decoded.right;
                } else {
                    throw new Error('Validation failed');
                }
            },
            () =>
                ({
                    type: 'ValidationError',
                    message: 'Response validation failed',
                } as PaymentError)
        );
    }

    getPublishableKey(): TaskEither<PaymentError, StripePublishableKey> {
        return pipe(
            this.fetchWithTrace(`${API_URL}/v1/payments/publishable-key`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            chain((response) =>
                response.ok
                    ? this.validateResponse<GetPublishableKeyResponse>(
                        response,
                        GetPublishableKeyResponse
                    )
                    : left({
                        type: 'ServerError',
                        message: `Server responded with an error: ${response.status}`,
                        statusCode: response.status,
                    } as PaymentError)
            ),
            map((validation) => validation.stripe_publishable_key)
        );
    }

    setupIntent(subscriptionPlan: SubscriptionPlan): TaskEither<PaymentError, StripeSetupIntentSecret> {
        return pipe(
            this.fetchWithTrace(`${API_URL}/v1/payments/setup-intent`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ subscription_plan: subscriptionPlan }),
            }),
            chain((response) =>
                response.ok
                    ? this.validateResponse<SetupIntentResponse>(response, SetupIntentResponse)
                    : left({
                        type: 'ServerError',
                        message: `Server responded with an error: ${response.status}`,
                        statusCode: response.status,
                    } as PaymentError)
            ),
            map((validation) => validation.setup_intent_secret)
        );
    }

    cancelSubscription(): TaskEither<PaymentError, void> {
        return pipe(
            this.fetchWithTrace(`${API_URL}/v1/payments/cancel-subscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            chain((response) =>
                response.ok
                    ? right(undefined)
                    : left({
                        type: 'ServerError',
                        message: `Server responded with an error: ${response.status}`,
                        statusCode: response.status,
                    } as PaymentError)
            )
        );
    }
}
